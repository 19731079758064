import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { notify } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';

import { notifyServerError } from '@/helpers/errors';
import counterDownArrow from '@/images/counterDownArrow.svg';
import counterUpArrow from '@/images/counterUpArrow.svg';
import greenDot from '@/images/greenDot.svg';
import redDot from '@/images/redDot.svg';
import { CounterModelType, useQuery } from '@/models';
import { CounterModalCallback, CounterModalCallbackResult } from '@/types';

import { ActionButton, CounterActions, CounterWrapper, KindIndicator, Title } from './styled';

interface CounterProps {
  counter: CounterModelType;
  selectedCounter?: CounterModelType;
  setSelectedCounter: Dispatch<SetStateAction<CounterModelType | undefined>>;
  setCounterModalCallback: Dispatch<SetStateAction<CounterModalCallback | null>>;
  selected: boolean;
  blurred?: boolean;
  disabled?: boolean;
}

const Counter: React.FC<CounterProps> = observer(
  ({ blurred, counter, setSelectedCounter, setCounterModalCallback, selectedCounter, selected, disabled }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { setQuery } = useQuery();
    const intl = useIntl();

    const onCounterValueButtonClick = (id: string) => {
      if (!disabled) {
        if (selectedCounter?.id === id) {
          setSelectedCounter(undefined);
          return;
        }
        setSelectedCounter(counter);
      }
    };
    const onDecreaseCounterByOneButtonClick = (note = ''): CounterModalCallbackResult => {
      if (!disabled && counter.value !== undefined) {
        const newValue = counter.value - 1;
        setQuery(
          counter.setCounterValue({
            newValue,
            currentValue: counter.value,
            note,
            onSuccess: () => {
              notify.success(
                intl.formatMessage(
                  {
                    defaultMessage: 'Decreased {counterName} by 1',
                    description: 'Counters decrease counter by one toast message',
                  },
                  { counterName: counter.name },
                ),
              );
            },
            onError: (error) => {
              notifyServerError(error, intl);
            },
          }),
        );
      }
      setCounterModalCallback(null);

      return null;
    };
    const onIncreaseCounterByOneButtonClick = (note = ''): CounterModalCallbackResult => {
      if (!disabled && counter.value !== undefined) {
        const newValue = counter.value + 1;
        setQuery(
          counter.setCounterValue({
            newValue,
            currentValue: counter.value,
            note,
            onSuccess: () => {
              notify.success(
                intl.formatMessage(
                  {
                    defaultMessage: 'Increased {counterName} by 1',
                    description: 'Counters increase counter by one toast message',
                  },
                  { counterName: counter.name },
                ),
              );
            },
            onError: (error) => {
              notifyServerError(error, intl);
            },
          }),
        );
      }
      setCounterModalCallback(null);

      return null;
    };

    return (
      <CounterWrapper blurred={blurred} data-testid={`counter-${counter.id}`} disabled={disabled}>
        <CounterActions>
          <ActionButton
            secondary
            data-testid={`counter-decreaseByOneAction-${counter.id}`}
            gridArea="decreaseAction"
            onClick={(e) => {
              e.stopPropagation();

              counter.hasNote
                ? setCounterModalCallback(() => onDecreaseCounterByOneButtonClick)
                : onDecreaseCounterByOneButtonClick();
            }}
          >
            <img src={counterDownArrow} alt="arrow pointing down" />
          </ActionButton>
          <ActionButton
            style={{ marginRight: '20px', minWidth: '105px' }}
            data-testid={`counter-value-${counter.id}`}
            selected={selected}
            gridArea="value"
            onClick={(e) => {
              e.stopPropagation();

              onCounterValueButtonClick(counter.id);
            }}
          >
            {counter.value}
          </ActionButton>

          <ActionButton
            secondary
            data-testid={`counter-increaseByOneAction-${counter.id}`}
            gridArea="increaseAction"
            onClick={(e) => {
              e.stopPropagation();

              counter.hasNote
                ? setCounterModalCallback(() => onIncreaseCounterByOneButtonClick)
                : onIncreaseCounterByOneButtonClick();
            }}
          >
            <img src={counterUpArrow} alt="arrow pointing up" />
          </ActionButton>
        </CounterActions>
        <Title
          data-testid={`counter-title-${counter.id}`}
          onClick={(e) => {
            e.stopPropagation();

            onCounterValueButtonClick(counter.id);
          }}
        >
          {counter.name}
        </Title>
        <KindIndicator src={counter.kind === 'bad' ? redDot : greenDot} />
      </CounterWrapper>
    );
  },
);
export default Counter;
